import Image from 'next/image';
import { useQuery } from 'react-query';

import {
  IconName,
  staticImportIcon,
} from '@/root/src/utils/static-import-icon';
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import useMobileDetection from '../../hooks/use-mobile-detection';
import useTrans from '../../hooks/use-trans';
import { fetchProjectDetails } from '../../utils/apis/project-details';

const SocialButton = () => {
  const trans = useTrans();
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  if (!tokenSymbol) {
    throw new Error('Token symbol is not set');
  }

  return (
    <Stack direction="row" gap={3}>
      <Stack className="social-button-stack">
        <Tooltip title="Web" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-web"
            href={trans[tokenSymbol]?.website_url}
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.WEB)}
              alt={IconName.WEB}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="Telegram" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-telegram"
            href={trans[tokenSymbol]?.telegram_url}
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.TELEGRAM)}
              alt={IconName.TELEGRAM}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
      <Stack className="social-button-stack">
        <Tooltip title="X" placement="top" TransitionComponent={Fade}>
          <Link
            data-testid="social-link-x"
            href={trans[tokenSymbol]?.x_url}
            target="_blank"
            rel="noreferrer"
            height={40}
          >
            <Image
              src={staticImportIcon(IconName.X)}
              alt={IconName.X}
              width={40}
              height={40}
            />
          </Link>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const ProjectHeader = () => {
  const trans = useTrans();
  const isLg = useMediaQuery('(min-width:1200px)');
  const isMd = useMediaQuery('(min-width:810px)');
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL || '';

  return (
    <Grid container justifyContent="space-between">
      <Grid
        item
        xs={12}
        lg={6}
        container
        className={isLg ? '' : 'flex justify-center'}
      >
        <Image
          src={`/images/logos/${tokenSymbol}.png`}
          alt={tokenSymbol}
          width={72}
          height={72}
          className={isLg ? 'mr-8' : isMd ? 'mb-8' : 'mb-4'}
        />{' '}
        <Grid
          item
          xs={12}
          lg={3}
          className={`${isLg ? '' : isMd ? 'mb-8' : 'mb-4'}`}
        >
          <Typography
            className={`${
              isLg ? '' : 'flex justify-center'
            } text-white font-dm_mono ${isMd ? 'text-[20px]' : 'text-[14px]'}`}
          >
            {trans[tokenSymbol]?.token_name}
          </Typography>
          <Typography
            className={`${
              isLg ? '' : 'flex justify-center'
            } text-white font-cc uppercase ${
              isMd ? 'text-[32px]' : 'text-[20px]'
            }`}
          >
            {process.env.NEXT_PUBLIC_TOKEN_SYMBOL}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        container
        justifyContent={isLg ? 'flex-end' : 'center'}
        className="flex items-center"
      >
        <Box
          className={
            isLg
              ? 'mr-8'
              : `w-full flex justify-center ${isMd ? 'mb-8' : 'mb-4'}`
          }
        >
          <SocialButton />
        </Box>
        <Button
          component="a"
          href={trans[tokenSymbol]?.guide_url}
          target="_blank"
          rel="noreferrer"
          data-testid="social-link-guide"
          className="rounded-full px-6 py-3 leading-6 h-12 text-[#6dfcd1] bg-[rgba(255,255,255,0.16)] hover:bg-[rgba(109,252,209)] hover:text-black"
        >
          <Typography className="font-normal font-cc uppercase text-sm md:text-base">
            Guide
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

const ProjectDetails = () => {
  const trans = useTrans();
  const tokenSymbol = process.env.NEXT_PUBLIC_TOKEN_SYMBOL;
  if (!tokenSymbol) {
    throw new Error('Token symbol is not set');
  }
  const isMd = useMediaQuery('(min-width:810px)');
  const isMobile = useMobileDetection();

  const {
    data: projectDetailsApiData,
    isLoading: projectDetailsLoading,
    isError: projectDetailsError,
    refetch: projectDetailsRefetch,
  } = useQuery('projectDetails', fetchProjectDetails);

  function formatDateTime(dateStr: string): string {
    const date = new Date(dateStr);

    // Convert to UTC time
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      hour12: true,
      timeZone: 'UTC',
    };

    const utcTime = date.toLocaleString('en-US', options);
    const day = date.getUTCDate();
    const month = date.toLocaleString('en-US', {
      month: 'long',
      timeZone: 'UTC',
    });

    return `${utcTime} UTC, ${month} ${day}`;
  }

  return (
    <Stack gap={{ xs: 3, md: 6 }} className="">
      <ProjectHeader />
      <Divider
        sx={{
          borderColor: 'rgba(255, 255, 255, 0.08)',
          borderBottomWidth: '2px',
        }}
      />
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6} className="pr-6">
          <Typography
            className={`${
              isMobile ? 'text-[14px]' : 'text-[20px]'
            } text-white font-normal font-cc uppercase mb-5`}
          >
            About
          </Typography>
          <Box
            className={`${
              isMobile ? 'text-[14px]' : 'text-base'
            } text-white font-normal font-dm_mono_regular mb-12`}
            dangerouslySetInnerHTML={{
              __html: trans[tokenSymbol]?.description,
            }}
          />
          <Typography
            className={`${
              isMobile ? 'text-[14px]' : 'text-[20px]'
            } text-white font-normal font-cc uppercase mb-5`}
          >
            Eligibility
          </Typography>
          <Box
            className={`${
              isMobile ? 'text-[14px]' : 'text-[16px]'
            } text-white font-normal font-dm_mono_regular mb-5`}
            dangerouslySetInnerHTML={{
              __html: trans[tokenSymbol]?.eligibility(
                new Intl.NumberFormat('en-US').format(projectDetailsApiData?.eligibleAmount || 0),
              ),
            }}
          />
          <Stack direction="column" gap={1}>
            <Link
              className="w-fit"
              href="https://jup.ag/swap/SOL-SOLCAT"
              target="_blank"
              rel="noopener"
            >
              <Typography className="font-dm_mono_regular underline text-[#6dfcd1]">
                Buy SOLCAT here
              </Typography>
            </Link>
            <Link
              className="w-fit"
              href="https://staking.catsolhat.com/"
              target="_blank"
              rel="noopener"
            >
              <Typography className="font-dm_mono_regular underline text-[#6dfcd1]">
                Stake SOLCAT here
              </Typography>
            </Link>
          </Stack>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography
            className={`${
              isMobile ? 'text-[14px]' : 'text-[20px] leading-7'
            } text-white font-normal font-cc uppercase mb-5`}
          >
            Token metrics
          </Typography>

          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-4"
          >
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                Token Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular w-auto`}
                align={isMd ? 'right' : 'left'}
              >
                {trans[tokenSymbol]?.token_name}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.08)',
              borderBottomWidth: '2px',
            }}
          />
          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-3"
          >
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                Token Symbol
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular`}
                align={isMd ? 'right' : 'left'}
              >
                {process.env.NEXT_PUBLIC_TOKEN_SYMBOL}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.08)',
              borderBottomWidth: '2px',
            }}
          />

          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-3"
          >
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                Blockchain
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular`}
                align={isMd ? 'right' : 'left'}
              >
                {trans[tokenSymbol]?.blockchain}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.08)',
              borderBottomWidth: '2px',
            }}
          />

          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-3"
            direction="row"
          >
            <Grid item xs={12} md>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                CA
              </Typography>
            </Grid>
            <Grid item xs={12} md={11}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } break-words text-white font-dm_mono_regular`}
                align={isMd ? 'right' : 'left'}
              >
                {process.env.NEXT_PUBLIC_SHOW_MINT_ADDRESS === 'true'
                  ? process.env.NEXT_PUBLIC_MINT_ADDRESS
                  : 'TBA'}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.08)',
              borderBottomWidth: '2px',
            }}
          />

          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-3"
          >
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                TGE
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular`}
                align={isMd ? 'right' : 'left'}
              >
                {projectDetailsApiData?.tge
                  ? formatDateTime(projectDetailsApiData?.tge)
                  : 'TBA'}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.08)',
              borderBottomWidth: '2px',
            }}
          />

          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-3"
          >
            <Grid item xs={12} md>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                Total Supply
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular`}
                align={isMd ? 'right' : 'left'}
              >
                {new Intl.NumberFormat('en-US').format(
                  projectDetailsApiData?.totalSupply || 0,
                )}{' '}
                {process.env.NEXT_PUBLIC_TOKEN_SYMBOL}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            sx={{
              borderColor: 'rgba(255, 255, 255, 0.08)',
              borderBottomWidth: '2px',
            }}
          />

          <Grid
            container
            spacing={{ xs: 1, md: 2 }}
            justifyContent="space-between"
            className="py-3"
          >
            <Grid item xs={12} md>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular text-opacity-50`}
              >
                Airdrop Allocation
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography
                className={`${
                  isMobile ? 'text-[12px]' : 'text-[18px] leading-[26px]'
                } text-white font-dm_mono_regular`}
                align={isMd ? 'right' : 'left'}
              >
                {new Intl.NumberFormat('en-US').format(
                  projectDetailsApiData?.airdropAllocation || 0,
                )}{' '}
                {process.env.NEXT_PUBLIC_TOKEN_SYMBOL}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        sx={{
          borderColor: 'rgba(255, 255, 255, 0.08)',
          borderBottomWidth: '2px',
        }}
        className="mb-6 md:mb-12"
      />
    </Stack>
  );
};

export default ProjectDetails;
